import { render, staticRenderFns } from "./PanelLayout.vue?vue&type=template&id=25567a00&scoped=true"
import script from "./PanelLayout.vue?vue&type=script&lang=js"
export * from "./PanelLayout.vue?vue&type=script&lang=js"
import style0 from "./PanelLayout.vue?vue&type=style&index=0&id=25567a00&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.10.0_prettier@2.8.8_vue-template-compiler@2.7.16_webpack@5.91.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25567a00",
  null
  
)

export default component.exports